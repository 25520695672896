import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom";

const CheckTimelinePic = () => {
    const [imgsrc, setimgsrc] = useState('')
    const [widthofimg, setwidthofimg] = useState('')

    const checkScreenWidth = () => {
        if(typeof window !== "undefined"){
            if(window.innerWidth >= 500){
                 setimgsrc('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Timeline-1-1-scaled.webp')
            }
            else {
                setimgsrc('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Mobile-Timeline-1-1.webp')
            }
            setwidthofimg((80 * window.innerWidth)/100)
        }
    }

    useEffect(() => {
      checkScreenWidth()
    }, [])
 
    
  return (
    <section className='relative w-full flex justify-center bg-gray-100 lg:pt-20 z-0 px-3'>
        <div className='max-w-7xl'>
            <h2 className='text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 text-center pt-5'>Humble beginnings to <span className='text-orange'><br/>Epic achievements</span></h2>
            <div className='w-full mx-auto py-20'>
                <img src={imgsrc} width={widthofimg} />
            </div>
        </div>
    </section>
  )
}

const rendercheckTimelinePic=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#timelineSection")
        if (id != null){
            ReactDOM.render(
                <CheckTimelinePic />,
                id
            )
        }
    }
}

setTimeout(rendercheckTimelinePic,100)

export default CheckTimelinePic