import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";
import ClientsModule from '../components/Homepage2023Content/ClientsModule';
import CheckTimelinePic from '../components/Testimonials/CheckTimelinePic';

const Aboutus = ({location}) => {

    
    useEffect(() => {
        AOS.init({
            once:true
        });
      }, []);

  return (
    <Layout location={location}>
        <section className="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden pt-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
            <div className="max-w-7xl flex flex-col xl:flex-row justify-center items-start z-10 lg:px-3 relative">
                <div className="w-full xl:w-8/12 flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
                        {/* <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" /> */}
                        <h2 class="text-purple-100 text-sm lato">ABOUT US</h2>
                    </div>

                    <h1 className="text-purple-100 lg:pr-3 xl:w-10/12 text-4xl md:text-4xl xl:text-7xl font-bold text-center xl:text-left">Discover our <span className='text-orange'>Story</span></h1>


                    <p className="my-8 text-lg xl:text-xl text-purple-100 text-center xl:text-left md:w-10/12">We help organizations worldwide deliver a satisfying employee experience & foster meaningful connections among co-workers through innovative recognition & wellness solutions. </p>
                    <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/4 lg:w-1/2 xl:w-1/3 cursor-pointer">
                        <a className="vc-new-orange-btn-rounded-full lato mb-3 xl:mb-0 xl:mr-2" href="/careers/" target="_blank">Join our team</a>
                        {/* <a className="vc-new-white-border-btn-rounded-full lato rounded-full mt-3 xl:mt-0 xl:ml-2" href="#form">Watch Video</a> */}
                    </div>
                </div>     
                <div className='xl:w-5/12 xl:h-80'></div>   
                <div class="mx-auto xl:absolute xl:-bottom-20 xl:right-0 flex justify-center items-center h-full mt-6 xl:mt-0 lg:ml-3" data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/aboutus_heroimage-min-1-1.png" alt="Vantage Rewards" width="650" height= "600" /></div>
            </div>
        </section>
        <section className="w-full flex place-content-center relative overflow-x-hidden py-9 xl:pt-10 xl:pb-0 2xl:pt-5 overflow-y-hidden z-0 px-3 lg:px-8 xl:px-0" style={{ backgroundColor: '#fdf8f6'}}>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-sart w-full mb-0 lg:my-8 relative max-w-7xl">
                <div class="w-full h-full flex flex-col lg:text-left justify-center items-center lg:items-start z-10">
                    <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-6xl 2xl:text-6xl font-bold xl:text-left font-bold my-3">Our <span className= "text-orange">story</span></h2>
                    <p class="text-gray-250 text-lg xl:text-xl text-center lg:text-left my-3 leading-8">It all began in 2010 with a vision to help companies worldwide create winning work cultures. We believe fostering a positive and engaging work environment is the key to a happy & healthy workforce.</p>
                    <p class="text-gray-250 text-lg xl:text-xl text-center lg:text-left my-3 leading-8">Over the years, we have grown and evolved, but our zeal for improving the employee experience has remained constant. Today, we proudly offer award-winning recognition and wellness solutions to 700+ global companies.</p>
                </div>
                <div class="w-full flex justify-center items-center z-10">
                    <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Our-story.webp" width="620" height="400" />
                </div>
                {/* <div class="z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0">
                    <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" />
                </div> */}
            </div>
       </section>
       <section className='mission-vision relative flex justify-center lg:items-center xl:items-end z-10 py-10 xl:py-0' style={{ backgroundColor: '#fefcf8'}}>
        <div className='grid lg:grid-cols-2 max-w-5xl gap-10 px-5'>
                <div className='rounded-lg bg-white shadow transform xl:translate-y-6 flex-col p-9'>
                    <h2 className='text-3xl pb-3'>Our <br/> <span className='text-orange text-4xl xl:text-7xl'>Mission</span></h2>
                    <p className='py-5 text-gray-500'>To become the world's number one employee engagement platform in the next five years.</p>
                </div>
                <div className='rounded-lg bg-white shadow flex-col transform xl:translate-y-12 p-9'>
                    <h2 className='text-3xl pb-3'>Our <br/> <span className='text-orange text-4xl xl:text-7xl'>Vision</span></h2>
                    <p className='py-5 text-gray-500'>With technology as our foundation, our vision is to create a world where every employee feels valued, engaged, and inspired to contribute their best towards their organization's success.</p>

                </div>
            </div>
       </section>
       {/* <section className='relative w-full flex justify-center bg-gray-100 lg:pt-20 z-0 px-3'>
            <div className='max-w-7xl'>
                <h2 className='text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center pt-5'>Humble beginnings to <span className='text-orange'><br/>Epic achievements</span></h2>
                <div className='w-full mx-auto py-20'>
                    <img src={imgsrc} width={widthofimg} />
                </div>
            </div>
       </section> */}

       <div id='timelineSection'></div>
   
       <section className='pt-10 xl:pt-20 pb-10'>
            <div className='max-w-7xl mx-auto px-5'>
                <h2 className='text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center'>Our <span className='text-orange'>core values</span></h2>
                <div className='grid xl:grid-cols-3 gap-5 xl:gap-16 xl:mt-20 xl:mb-10'>
                    <div className='my-5 flex-col justify-center items-center xl:my-0 border rounded-lg px-6 py-3' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "300">
                        <div className='w-full'><img className= 'py-5 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Career-Learning.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Learning</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>We continuously learn new skills and gain knowledge, adapt to changing circumstances, and stay competitive</p>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "400">
                        <div className='w-full'><img className= 'py-5 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-ownership.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Ownership</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>We take responsibility for our actions and hold ourselves accountable for fulfilling /completing our respective duties</p>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "500">
                        <div className='w-full'><img className= 'py-5 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-Team-effort.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Team effort</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>We function as one unified team with a shared commitment to achieving excellence towards a common goal</p>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3 xl:py-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600">
                        <div className='w-full xl:mt-6'><img className= 'py-5 xl:py-2 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-agility.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Agile with a vision</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>We're agile, adaptive, open to change and have a long term vision in reaching our goals & objectives</p>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3 xl:py-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "700">
                        <div className='w-full xl:mt-6'><img className= 'py-5 xl:py-2 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-wellness.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Wellbeing</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>From physical fitness to mental health, we believe in making every aspect of wellbeing a way of life</p>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3 xl:py-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "800">
                        <div className='w-full xl:mt-6'><img className= 'py-5 xl:py-2 mx-auto' loading= "lazy" src= "https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-data-driven-data.webp" /></div>
                        <h2 className='py-2 text-2xl text-center'>Data driven decisions</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>We ensure that our decisions or actions are evidence-based, free from personal biases or external pressure</p>
                    </div>
                </div>
                {/* <div className='w-full flex justify-center pb-10'><a href="#form" className='text-orange text-center'>Get our Checklist for Employee Rewards and Recognition now</a></div> */}
            </div>
        </section> 
         
        <section className='w-full flex flex-col justify-center items-center px-5 py-5 xl:py-16 bg-gray-100'>
            <div className='max-w-7xl my-5'>
                {/* <h2 className='text-gray-250 text-3xl xl:text-5xl xl:max-w-7xl mx-auto xl:mt-10 mb-16 xl:mb-10 font-bold text-center'>Trusted by <span className='text-orange'>700+</span> organizations, including Fortune 500s</h2> */}
                {/* <div className='grid md:grid-cols-2 xl:grid-cols-4 py-5 gap-5 items-center justify-center my-9'>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "300"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/TA_Digital-logo.png" alt="TA_Digital Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "400"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/piramal.png" alt="Parimal Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/wipro.png" alt="Wipro Logo" width="100" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "1000"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/landmark.png" alt="Landmark Logo" width="140" /></div>
                </div> */}

                <div id='clientsSection'></div>
                
                {/* <div class="c-logo-strip w-full py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div> */}
                {/* <a href="/corporates/" class="text-orange font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a> */}
            </div>
        </section>
        <section className='flex flex-col place-sontent-center py-5 xl;py-10'>
            <h2 className='text-gray-250 text-3xl xl:text-6xl xl:max-w-4xl mx-auto mt-5 mb-5 font-bold text-center'>Our board of <span className='text-orange'>advisors</span></h2>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 max-w-7xl mx-auto md:gap-x-20 md:gap-y-10 xl:gap-28 py-6 px-4 xl:px-0'>
                <div className='flex flex-col justify-center py-10 md:py-0 xl:py-10'>
                    <div className='rounded-full mx-auto relative'>
                        <img loading= "lazy" src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Adrian.webp' width="160" height="160" />
                        <a href='https://www.linkedin.com/in/adriangostick/' className='absolute top-0 right-0'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a>
                    </div>
                    <h2 className='text-2xl pt-5 text-center'>Adrian Gostick</h2>
                    <p className='py-2 text-center text-sm'>#1 New York Times bestselling author, Corporate Culture Expert, Engagement Expert, Leadership Expert, Managing Change Expert, Global Keynote Speaker.</p>
                </div>
                <div className='flex flex-col justify-center py-10 md:py-0 xl:py-10'>
                    <div className='rounded-full mx-auto relative'>
                        <img loading= "lazy" src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Chester.webp' width="160" height="160" />
                        <a href='https://www.linkedin.com/in/chesterelton/' className='absolute top-0 right-0'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a>
                    </div>
                    <h2 className='text-2xl pt-5 text-center'>Chester Elton</h2>
                    <p className='py-2 text-center text-sm'>#1 Bestselling Business Author, Leadership Expert, Organizational Culture Expert, Employee Engagement and Teamwork Expert, Global Keynote Speaker.</p>
                </div>
                <div className='flex flex-col justify-center py-10 md:py-0 xl:py-10'>
                    <div className='rounded-full mx-auto relative'>
                        <img loading= "lazy" src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Matt.webp' width="160" height="160" />
                        <a href='https://www.linkedin.com/in/mattinvr/' className='absolute top-0 right-0'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a>
                    </div>
                    <h2 className='text-2xl pt-5 text-center'>Matt Burns</h2>
                    <p className='py-2 text-center text-sm'>Founder & CEO of BentoHR, Hybrid Work Experience Architect, Thinking Inside the Box Podcast Host, Global HR Summit Founder, Speaker.</p>
                </div>
                <div className='flex flex-col justify-center py-10 md:py-0 xl:py-10'>
                    <div className='rounded-full mx-auto relative'>
                        <img loading= "lazy" src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Kaustabh.png' width="160" height="160" />
                        <a href='https://www.linkedin.com/in/kaustubhsonalkar/' className='absolute top-0 right-0'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a>
                    </div>
                    <h2 className='text-2xl pt-5 text-center'>Kaustubh Sonalkar</h2>
                    <p className='py-2 text-center text-sm'>Business Strategist, Transformation Expert, M&A and Growth Agent, People and Tech Leader, Mentor, and Best-selling Author. </p>
                </div>
            </div>
        </section>
        <section class="w-full flex place-content-center mb-10 xl:mt-16 lg:mb-20">
            <div class="w-5/6 xl:w-5/6 2xl:w-4/6 flex flex-col place-items-center p-5 pb-0 rounded-xl md:rounded-2xl overflow-hidden xl:rounded-4xl relative bg-indigo-100">
                <h2 class="font-bold text-purple-100 xl:text-5xl w-full md:w-10/12 mt-9 text-center pb-6">Delivering simple yet effective employee engagement solutions globally</h2>
                {/* <p class="text-center w-5/6 lg:w-4/6 py-3 text-lg xl:text-xl text-purple-100">Join our bandwagon and deliver a satisfying employee experience with our global employee engagement and wellness platform.</p> */}
                <div class="w-full pl-3 xl:pl-0 grid grid-cols-2 lg:grid-cols-5 justify-center items-center my-5">
                    <div class="flex justify-start md:justify-center items-center border-r border-purple-100">
                        <div class="flex flex-col items-start md:items-center xl:items-start justify-center">
                        <h2 class="text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">100+</h2>
                        <p class="text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Countries</p>
                        </div>
                    </div>
                    <div class="flex pl-3 xl:pl-0 justify-start md:justify-center items-center lg:border-r border-purple-100">
                        <div class="flex flex-col items-start md:items-center xl:items-start justify-center">
                        <h2 class="text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">10K+</h2>
                        <p class="text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Reward Options</p>
                        </div>
                    </div>
                    <div class="flex justify-start md:justify-center items-center border-r border-purple-100">
                        <div class="flex flex-col items-start md:items-center xl:items-start justify-center">
                        <h2 class="text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">16+</h2>
                        <p class="text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Global Langauges</p>
                        </div>
                    </div>
                    <div class="flex pl-3 xl:pl-0 justify-start md:justify-center items-center border-r border-white">
                        <div class="flex flex-col items-start md:items-center xl:items-start justify-center">
                        <h2 class="text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">700+</h2>
                        <p class="text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Clients</p>
                        </div>
                    </div>
                    <div class="flex pl-3 xl:pl-0 justify-start md:justify-center items-center ">
                        <div class="flex flex-col items-start md:items-center xl:items-start justify-center">
                        <h2 class="text-lg md:text-2xl lg:text-5xl text-newOrange font-bold text-left">2M+</h2>
                        <p class="text-left text-xs md:text-base lg:text-md xl:text-lg text-purple-100">Users</p>
                        </div>
                    </div>
                </div>
                <div class="w-full flex place-content-center pt-12">
                    <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/GLobe-Homepage.png" loading="lazy"/>
                </div>
                <div class="hidden lg:block absolute bottom-50 left-50 opacity-20 transform -translate-y-4 lg:-translate-y-1/4 xl:-translate-y-28 2xl:-translate-y-28 w-11/12 2xl:w-11/12 gstat"></div>
            </div>
        </section>
        <section className='mx-auto w-11/12 md:w-4/12 lg:w-3/12 xl:w-2/12 my-5 py-5'>
            <a href='/request-a-demo/' className='vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2 mx-auto'>Request a demo</a>
        </section>
        <section class="w-full flex flex-col place-items-center z-0 px-2">
            <div class="newSectionContainer flex flex-col justify-center items-center bg-white">
                <h2 class="text-gray-500 text-3xl xl:text-6xl text-center xl:text-6xl lg:mt-10 mb-3 font-bold text-center">
                    Winner of top global awards in Employee Recognition and Engagement
                </h2>
                <div className='w-full flex justify-center my-16'>
                    <img src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/About-us-badges.webp" width="700px" />
                </div>
            </div>
        </section>
        <section className='w-full flex place-content-center'>
            <div className='w-full py-10 pb-0 bgAboutUsFooter flex flex-col justify-center items-center'>
                <h2 className='text-gray-100 text-3xl xl:text-6xl xl:max-w-4xl mx-auto xl:mt-10 font-bold text-center transform xl:-translate-y-16'>Want to <span className='text-orange'>work</span> with us?</h2>
                <div className='w-8/12 mx-auto md:w-5/12 lg:w-3/12 xl:w-3/12 2xl:w-1/6 transform xl:-translate-y-16'><a className= "vc-new-orange-btn-rounded-full mx-auto my-5 xl:my-10 text-center" target="_blank" href="/careers/">Explore current openings</a></div>
                {/* <div className='mx-auto mt-20 max-w-7xl'>
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/CTA-Banner_IMG.webp" width="1280" height="313" />
                </div> */}
            </div>
        </section>

    </Layout>
  )
}

export default Aboutus